import React from "react";
import SetMetaTag from "../hooks/SetMetaTag";

const Home = () => {
  return (
    <div>
      <SetMetaTag
        url={window.location.href}
        title={"Ini Title"}
        description={"Ini Description"}
        image={"https://fergus.my.id/assets/img/profile-img.jpg"}
      />
      <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h1>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,
      maxime quidem impedit quibusdam quis doloribus omnis, obcaecati, ipsam
      repellat iure corporis neque beatae dicta voluptatibus ducimus quae
      reiciendis necessitatibus alias.
    </div>
  );
};

export default Home;
